//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Area } from "@antv/g2plot";
export default {
  name: "ChatTargetRank",
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    chartHeight: {
      type: String,
      default: "400px",
    },
  },
  data() {
    return {
      chart: null,
      chartColors: {
        名称: {
          color: "#1EB197",
          unit: "",
        },
        聊天总时长: {
          color: "#03ab68",
          unit: "小时",
        },
        时长占比: {
          color: "#eca85f",
          unit: "%",
        },
        鼠标点击速度: {
          color: "#f72323",
          unit: "次/分",
        },
        鼠标移动速度: {
          color: "#32f723",
          unit: "次/分",
        },
        键盘输入速度: {
          color: "#098dd8",
          unit: "次/分",
        },
      },
    };
  },
  watch: {
    chartData(val) {
      if (this.chart) {
        this.chart.changeData(val);
      }
    },
  },
  methods: {
    renderChart() {
      var _this = this;
      if (this.chart) {
        return;
      }
      this.chart = new Area(this.$refs.chart, {
        data: this.chartData,
        padding: "auto",
        xField: "TargetName",
        yField: "TotalTime",
        xAxis: {
          tickCount: 5,
          nice: true,
          label: {
            autoRotate: true,
            autoEllipsis: true,
          },
        },
        yAxis: {
          nice: true,
          label: {
            formatter: (text, item, index) => {
              return `${text}小时`;
            },
          },
        },
        meta: {
          TargetName: {
            alias: "名称",
          },
          TotalTime: {
            alias: "聊天总时长",
          },
          Percentage: {
            alias: "时长占比",
          },
          MouseClickSpeed: {
            alias: "鼠标点击速度",
          },
          MouseMoveSpeed: {
            alias: "鼠标移动速度",
          },
          KeystrokeSpeed: {
            alias: "键盘输入速度",
          },
        },
        color: "#1EB197",
        point: {
          size: 4,
          shape: "round",
          style: {
            fill: "white",
            stroke: "#1EB197",
            lineWidth: 2,
          },
        },
        tooltip: {
          position: "right",
          showMarkers: false,
          fields: [
            "TotalTime",
            "Percentage",
            "KeystrokeSpeed",
            "MouseClickSpeed",
            "MouseMoveSpeed",
          ],
          customContent: (name, items) => {
            const container = document.createElement("div");
            container.className = "g2-tooltip";
            const title = `<div class="g2-tooltip-title" style="margin-top: 12px;margin-bottom: 12px;">${name}</div>`;
            let listItem = "";
            items.forEach((item) => {
              var color = `${item?.mappingData?.color || item?.color}`;
              var itemMap = null;
              if (item) {
                if (_this.chartColors[item.name]) {
                  itemMap = _this.chartColors[item.name];
                  color = itemMap.color;
                }
              }
              listItem += `<li class="g2-tooltip-list-item" data-index={index} style="margin-bottom:4px;display:flex;align-items: center;">
          <span style="background-color:${color};" class="g2-tooltip-marker"></span>
          <span style="display:inline-flex;flex:1;justify-content:space-between">
          <span style="margin-right: 16px;">${item?.name}:</span><span>${
                item?.value
              }${itemMap ? itemMap.unit : ""}</span>
          </span>
      </li>`;
            });
            container.innerHTML = title + listItem;
            return container;
          },
        },
      });
      this.chart.on("element:click", (e) => void this.$emit("elementClick", e));
      this.chart.render();
    },
  },
  mounted() {
    this.renderChart();
  },
};
